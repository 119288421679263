export const inside11 = {
	title: '11',
	image: require('../../utopias/demo/imagenes/11.jpg'),
	pitch: -11,
	yaw: -3,
	hotSpot: {
		point1: {
			type: 'custom',
			pitch: -22.843353247441893, yaw: -86.40406991601182,
			cssClass: 'moveSceneRed',
			scene: 'inside10'
		},
		point2: {
			type: 'custom',
			pitch: -18.40656291535953, yaw: 13.36687814145533,
			hfo: 130,
			cssClass: 'moveSceneBlue',
			scene: 'inside12'
		}
	}
}