import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';

document.title = "Aritoteles 216 - Casa en Renta";
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    
    <App />
    
);

reportWebVitals();
