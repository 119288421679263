import Scene from './components/scenaPersonal';
import './styles/index.css';
import React, { useEffect,useState  } from 'react';
function App() {
  const [cursorVisible] = useState(true);
  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    /* const handleMouseEnter = () => {
      setCursorVisible(true);
    };

    const handleMouseLeave = () => {
      setCursorVisible(false);
    }; */


    return () => {
    
    };
  }, []);

  useEffect(() => {
    const handleOrientation = (event) => {
      const beta = event.beta;
      const gamma = event.gamma;
      const velocidad = 10;
      const nuevoX = cursorVisible ? cursorPosition.x + gamma * velocidad : 0;
      const nuevoY = cursorVisible ? cursorPosition.y + beta * velocidad : 0;

      const maxY = window.innerHeight;

      if (nuevoX > 0 && nuevoX < maxY && nuevoY > 0 && nuevoY < maxY) {
        setCursorPosition({ x: nuevoX, y: nuevoY });
        
      }
    };

    window.addEventListener('deviceorientation', handleOrientation);

    return () => {
      window.removeEventListener('deviceorientation', handleOrientation);
    };
  }, [cursorVisible, cursorPosition]); // Agregamos una dependencia vacía para asegurarnos de que el efecto se ejecute solo una vez
  return (
    <div className="App">
      
      <Scene />
      
    </div>
  );
}

export default App;
