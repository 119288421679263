export const inside14 = {
	title: '14',
	image: require('../../utopias/demo/imagenes/14.jpg'),
	pitch: -11,
	yaw: -3,
	hotSpot: {
		point1: {
			type: 'custom',
			pitch: -27.160675070465793, yaw: -177.7462013380612,
			cssClass: 'moveSceneRed',
			scene: 'inside11'
		},
		point2: {
			type: 'custom',
			pitch: -27.12451151820973, yaw: -2.50178773257646,
			hfo: 130,
			cssClass: 'moveSceneBlue',
			scene: 'inside12'
		}
	}
}