export const inside12 = {
	title: '12',
	image: require('../../utopias/demo/imagenes/12.jpg'),
	pitch: -11,
	yaw: -3,
	hotSpot: {
		point1: {
			type: 'custom',
			pitch: -32.887229473158094, yaw: 4.623833342770463,
			cssClass: 'moveSceneRed',
			scene: 'inside13'
		},
		point2: {
			type: 'custom',
			pitch: -20.082672573895803, yaw: -174.90055051278358,
			hfo: 130,
			cssClass: 'moveSceneBlue',
			scene: 'inside14'
		}
	}
}