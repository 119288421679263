export const inside3 = {
	title: '2 - Puerta a la Cocina',
	image: require('../../utopias/demo/imagenes/3.jpg'),
	pitch: -11,
	yaw: -3,
	hotSpot: {
		point1: {
			type: 'custom',
			pitch: -24.779671541733695, yaw: -92.32867824361054,
			cssClass: 'moveSceneRed',
			scene: 'inside2'
		},
		point2: {
			type: 'custom',
			pitch: -8.203600355924445, yaw: -159.64167782695716,
			hfo: 130,
			cssClass: 'moveSceneBlue',
			scene: 'inside4'
		}
	}
}