import { inside1 } from './scenas/inside1';
import { inside2 } from './scenas/inside2';
import { inside3 } from './scenas/inside3';
import { inside4 } from './scenas/inside4';
import { inside5 } from './scenas/inside5';
import { inside6 } from './scenas/inside6';
import { inside7 } from './scenas/inside7';
import { inside8 } from './scenas/inside8';
import { inside9 } from './scenas/inside9';
import { inside10 } from './scenas/inside10';
import { inside11 } from './scenas/inside11';
import { inside12 } from './scenas/inside12';
import { inside13 } from './scenas/inside13';
import { inside14 } from './scenas/inside14';
import { inside15 } from './scenas/inside15';
import { inside16 } from './scenas/inside16';
import { inside17 } from './scenas/inside17';
import { inside18 } from './scenas/inside18';
import { inside19 } from './scenas/inside19';
import { inside20 } from './scenas/inside20';
import { inside21 } from './scenas/inside21';
import { inside22 } from './scenas/inside22';

const Scenes = {
	inside1: inside1,
	inside2: inside2,
	inside3: inside3,
	inside4: inside4,
	inside5: inside5,
	inside6: inside6,
	inside7: inside7,
	inside8: inside8,
	inside9: inside9,
	inside10: inside10,
	inside11: inside11,
	inside12: inside12,
	inside13: inside13,
	inside14: inside14,
	inside15: inside15,
	inside16: inside16,
	inside17: inside17,
	inside18: inside18,
	inside19: inside19,
	inside20: inside20,
	inside21: inside21,
	inside22: inside22,
}

export default Scenes