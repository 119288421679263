export const inside2 = {
	title: '2 - Puerta Principal',
	image: require('../../utopias/demo/imagenes/2.jpg'),
	pitch: -11,
	yaw: -3,
	hotSpot: {
		point1: {
			type: 'custom',
			pitch: -17.21856492482528, yaw: 130.95784317336526,
			cssClass: 'moveSceneRed',
			scene: 'inside3'
		},
		point2: {
			type: 'custom',
			pitch: -17.127541991189055, yaw: -3.150087215751169,
			hfo: 130,
			cssClass: 'moveSceneBlue',
			scene: 'inside6'
		},
		point3: {
			type: 'custom',
			pitch: -13.44762689693008, yaw: -116.28293241352694,
			hfo: 130,
			cssClass: 'moveSceneBlue',
			scene: 'inside4'
		}
	}
}