export const inside18 = {
	title: '18',
	image: require('../../utopias/demo/imagenes/18.jpg'),
	pitch: -11,
	yaw: -3,
	hotSpot: {
		point1: {
			type: 'custom',
			pitch: -46.62226523103293, yaw: -46.27846274079903,
			cssClass: 'moveSceneRed',
			scene: 'inside16'
		},
	}
}