export const inside21 = {
	title: '21',
	image: require('../../utopias/demo/imagenes/21.jpg'),
	pitch: -11,
	yaw: -3,
	hotSpot: {
		point1: {
			type: 'custom',
			pitch: -20.22349468697241, yaw: 125.07584513046393,
			cssClass: 'moveSceneRed',
			scene: 'inside20'
		},
		point2: {
			type: 'custom',
			pitch: 79.22981520753285, yaw: 1.0873446241185745,
			hfo: 130,
			cssClass: 'moveSceneBlue',
			scene: 'inside22'
		}
	}
}