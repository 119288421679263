export const inside7 = {
	title: '7 - Cocina',
	image: require('../../utopias/demo/imagenes/7.jpg'),
	pitch: -11,
	yaw: -3,
	hotSpot: {
		point1: {
			type: 'custom',
			pitch: -4.914310536942613,
			yaw: -21.301542921333805,
			cssClass: 'moveSceneRed',
			scene: 'inside9'
		},
/* 		point2: {
			type: 'custom',
			pitch: -2.348312831223886,
			yaw: 36.67057328725777,
			hfo: 130,
			cssClass: 'moveSceneBlue',
			scene: 'inside7'
		}, */
		point3: {
			type: 'custom',
			pitch: -26.530313343868453, yaw: -175.13910327684653,
			hfo: 130,
			cssClass: 'moveSceneBlue',
			scene: 'inside6'
		}
	}
}