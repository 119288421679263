export const inside8 = {
	title: '8 - Pasillo Central',
	image: require('../../utopias/demo/imagenes/8.jpg'),
	pitch: -11,
	yaw: -3,
	hotSpot: {
		point1: {
			type: 'custom',
			pitch: -23.500167234928533, yaw: -160.4355589781631,
			cssClass: 'moveSceneRed',
			scene: 'inside6'
		},
		point2: {
			type: 'custom',
			pitch: -31.912834055472967, yaw: -11.292458320652113,
			hfo: 130,
			cssClass: 'moveSceneBlue',
			scene: 'inside15'
		}
		,
		point3: {
			type: 'custom',
			pitch: -23.37803715319503, yaw: 38.22925697740469,
			hfo: 130,
			cssClass: 'moveSceneBlue',
			scene: 'inside16'
		},
		point4: {
			type: 'custom',
			pitch: -46.135050496735815, yaw: 85.72885409774369,
			hfo: 130,
			cssClass: 'moveSceneBlue',
			scene: 'inside19'
		}
	}
}