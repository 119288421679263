export const inside10 = {
	title: '10',
	image: require('../../utopias/demo/imagenes/10.jpg'),
	pitch: -11,
	yaw: -3,
	hotSpot: {
		point1: {
			type: 'custom',
			pitch: -26.26305672216163, yaw: 10.658185108916387,
			cssClass: 'moveSceneRed',
			scene: 'inside11'
		},
		point2: {
			type: 'custom',
			pitch: -27.097106735993822, yaw: -173.6815709196764,
			hfo: 130,
			cssClass: 'moveSceneBlue',
			scene: 'inside9'
		}
	}
}