export const inside5 = {
	title: '5 - Jardiera',
	image: require('../../utopias/demo/imagenes/5.jpg'),
	pitch: -11,
	yaw: -3,
	hotSpot: {
		point1: {
			type: 'custom',
			pitch: -24.987623624106455, yaw: 151.49137643170818,
			cssClass: 'moveSceneRed',
			scene: 'inside4'
		},
	}
}