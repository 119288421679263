export const inside22 = {
	title: '22',
	image: require('../../utopias/demo/imagenes/22.jpg'),
	pitch: -11,
	yaw: -3,
	hotSpot: {
		point1: {
			type: 'custom',
			pitch: -64.5362445523429, yaw: 151.507641846436,
			cssClass: 'moveSceneRed',
			scene: 'inside20'
		},
		point2: {
			type: 'custom',
			pitch: -78.59440437556913, yaw: -132.12922854365596,
			hfo: 130,
			cssClass: 'moveSceneBlue',
			scene: 'inside21'
		}
	}
}