export const inside17 = {
	title: '17',
	image: require('../../utopias/demo/imagenes/17.jpg'),
	pitch: -11,
	yaw: -3,
	hotSpot: {
		point1: {
			type: 'custom',
			pitch: -44.40538949302303, yaw: 43.158507604573394,
			cssClass: 'moveSceneRed',
			scene: 'inside15'
		}
	}
}