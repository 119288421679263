export const inside16 = {
	title: '16',
	image: require('../../utopias/demo/imagenes/16.jpg'),
	pitch: -11,
	yaw: -3,
	hotSpot: {
		point1: {
			type: 'custom',
			pitch: -31.533937286009202, yaw: -14.40749828514096,
			cssClass: 'moveSceneRed',
			scene: 'inside18'
		},
		point2: {
			type: 'custom',
			pitch: -48.317273707956936, yaw: -153.99072482232958,
			hfo: 130,
			cssClass: 'moveSceneBlue',
			scene: 'inside15'
		},
		point3: {
			type: 'custom',
			pitch: -41.8780117985852, yaw: 153.20686256291197,
			hfo: 130,
			cssClass: 'moveSceneBlue',
			scene: 'inside8'
		}
	}
}