export const inside15 = {
	title: '15',
	image: require('../../utopias/demo/imagenes/15.jpg'),
	pitch: -11,
	yaw: -3,
	hotSpot: {
		point1: {
			type: 'custom',
			pitch: -40.44633266347472, yaw: -121.9271493965536,
			cssClass: 'moveSceneRed',
			scene: 'inside8'
		},
		point2: {
			type: 'custom',
			pitch: -32.22706937851363, yaw: 25.404677303238223,
			hfo: 130,
			cssClass: 'moveSceneBlue',
			scene: 'inside17'
		},
		point3: {
			type: 'custom',
			pitch: -44.33394868725949, yaw: 167.79478478842668,
			hfo: 130,
			cssClass: 'moveSceneBlue',
			scene: 'inside16'
		}
	}
}