export const inside4 = {
	title: 'Entrada',
	image: require('../../utopias/demo/imagenes/4.jpg'),
	pitch: -11,
	yaw: -3,
	hotSpot: {
		point1: {
			type: 'custom',
			pitch: -6.976585228871835, yaw: -9.657861502265568,
			cssClass: 'moveSceneRed',
			scene: 'inside2'
		},
		point2: {
			type: 'custom',
			pitch: -2.348312831223886,
			yaw: 36.67057328725777,
			hfo: 130,
			cssClass: 'moveSceneBlue',
			scene: 'inside3'
		},
		point3: {
			type: 'custom',
			pitch: -17.967666016776235, yaw: -91.97821502717002,
			hfo: 130,
			cssClass: 'moveSceneBlue',
			scene: 'inside5'
		}
	}
}