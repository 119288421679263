export const inside6 = {
	title: '6',
	image: require('../../utopias/demo/imagenes/6.jpg'),
	pitch: -11,
	yaw: -3,
	hotSpot: {
		point1: {
			type: 'custom',
			pitch: -25.254528557423424, yaw: 69.8134473321216,
			cssClass: 'moveSceneRed',
			scene: 'inside7'
		},
		point2: {
			type: 'custom',
			pitch: -16.352468786747316, yaw: 33.31103616819923,
			hfo: 130,
			cssClass: 'moveSceneBlue',
			scene: 'inside8'
		},
		point3: {
			type: 'custom',
			pitch: -36.55397763031412, yaw: 129.2337871628656,
			hfo: 130,
			cssClass: 'moveSceneBlue',
			scene: 'inside2'
		}
	}
}