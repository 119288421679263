export const inside1 = {
	title: '1 - Entrada Garage',
	image: require('../../utopias/demo/imagenes/1.jpg'),
	pitch: -11,
	yaw: -3,
	hotSpot: {
		point1: {
			type: 'custom',
			pitch: -13.196140701390071, yaw: -5.011280624865324,
			cssClass: 'moveSceneRed',
			scene: 'inside2'
		},
		point2: {
			type: 'custom',
			pitch: -22.719572688070876, yaw: 61.46705433671099,
			hfo: 130,
			cssClass: 'moveSceneBlue',
			scene: 'inside3'
		},
		point3: {
			type: 'custom',
			pitch: -17.562474659353715, yaw: -179.87080401450743,
			hfo: 130,
			cssClass: 'moveSceneBlue',
			scene: 'inside4'
		}
	}
}