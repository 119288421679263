export const inside20 = {
	title: '20',
	image: require('../../utopias/demo/imagenes/20.jpg'),
	pitch: -11,
	yaw: -3,
	hotSpot: {
		point1: {
			type: 'custom',
			pitch: -59.34367946211669, yaw: 150.43009503809117,
			cssClass: 'moveSceneRed',
			scene: 'inside19'
		},
		point2: {
			type: 'custom',
			pitch: -46.78302676983087, yaw: -117.95627461409553,
			hfo: 130,
			cssClass: 'moveSceneBlue',
			scene: 'inside21'
		}
	}
}