export const inside13 = {
	title: '13',
	image: require('../../utopias/demo/imagenes/13.jpg'),
	pitch: -11,
	yaw: -3,
	hotSpot: {
		point1: {
			type: 'custom',
			pitch: -38.85759605714686, yaw: -49.65444441481776,
			cssClass: 'moveSceneRed',
			scene: 'inside12'
		},
	}
}