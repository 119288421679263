export const inside9 = {
	title: '9 - Patio',
	image: require('../../utopias/demo/imagenes/9.jpg'),
	pitch: -11,
	yaw: -3,
	hotSpot: {
		point1: {
			type: 'custom',
			pitch: -24.93796551466327, yaw: -153.26462559708241,
			cssClass: 'moveSceneRed',
			scene: 'inside7'
		},
		point2: {
			type: 'custom',
			pitch: -20.770773079643597, yaw: 6.799881813979204,
			hfo: 130,
			cssClass: 'moveSceneBlue',
			scene: 'inside10'
		}
	}
}