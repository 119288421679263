import React, { useState } from 'react';
import { Pannellum } from 'pannellum-react';
import dataScene from '../helpers/dataScene';
import { UseModal } from '../hooks/useModal';

export default function Scene() {
    const {  openModal } = UseModal(false);
    const [scene, setScene] = useState(dataScene['inside4']);// 4
    const [ setModel] = useState(null);

    const hotSpots = (element, i) => {
        if (element.cssClass === 'hotSpotElement') {
            return (
                <Pannellum.Hotspot
                    key={i}
                    type="custom"
                    pitch={element.pitch}
                    yaw={element.yaw}
                    handleClick={() => { openModal(); setModel(element.nameModel) }}
                    cssClass={element.cssClass}
                />
            );
        }

        else if (element.cssClass.includes('moveScene'))
            return (
                <Pannellum.Hotspot
                    key={i}
                    type="custom"
                    pitch={element.pitch}
                    yaw={element.yaw}
                    handleClick={(evt, args) => {
                        
                        setScene(dataScene[element.scene]);
                    }}
                    cssClass={element.cssClass}
                    URL={element.url}
                />
            );
        else if (element.cssClass === 'link' || element.cssClass === 'link2' || element.cssClass === 'link3')
            return (
                <Pannellum.Hotspot
                    key={i}
                    type={element.type}
                    pitch={element.pitch}
                    yaw={element.yaw}
                    handleClick={() => { window.open(element.link, '_blank'); }}
                    cssClass={element.cssClass}
                    URL={element.url}
                />
            );

    }

    return (
        <div>
            <div className="flex">
                <Pannellum
                    width={'100%' }
                    height={'100vh'}
                    title={scene.title}
                    image={scene.image}
                    pitch={-12.276868339999044}
                    yaw={125.5699735882686}
                    hfov={150}
                    autoLoad
                    showControls={true}
                    showFullscreenCtrl={true}
                    showZoomCtrl={false}
                    orientationOnByDefault={true}
                    hotspotDebug={true}
                    vrControls={false}
                    webVR={false}
                >
                    {Object.values(scene.hotSpot).map((element, i) => (hotSpots(element, i)))}
                </Pannellum>
            </div>
        </div>


    );

}

