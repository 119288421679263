export const inside19 = {
	title: '19',
	image: require('../../utopias/demo/imagenes/19.jpg'),
	pitch: -11,
	yaw: -3,
	hotSpot: {
		point1: {
			type: 'custom',
			pitch: -65.67167852008367, yaw: 38.14377352855777,
			cssClass: 'moveSceneRed',
			scene: 'inside20'
		},
		point3: {
			type: 'custom',
			pitch: -56.28758919177727, yaw: 171.24286353496967,
			hfo: 130,
			cssClass: 'moveSceneBlue',
			scene: 'inside8'
		}
	}
}